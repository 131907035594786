.d-tree-container {
  list-style: none;
  padding: 0;
}

.d-tree-node {
  padding: 0.75rem 1.25rem;
}

.d-tree-toggler.active {
  transform: rotate(45deg);
}
