.chat-message {
  bottom: -15px !important;
}

.com-dp {
  position: absolute;
  height: 9rem;
  width: 9rem;
  bottom: -3rem;
  top: auto;
  left: auto;
  right: 5%;
  border-radius: 50%;
}

.p-image-chat {
  width: 60px;
  height: 60px;
  border: 1px solid #eeeeee;
}

.bg-gray-1 {
  background-color: #f5f5f5 !important;
  color: #000 !important;
}

.font-size-10 {
  font-size: 0.7rem;
}

.width-10 {
  width: 10px !important;
}

.width-80 {
  width: 80px !important;
}

.vh-50 {
  height: 50vh;
}

.vh-80 {
  height: 80vh;
}

.bg-active {
  background-color: #bcf7ff9d !important;
}

a.bg-active:hover,
a.bg-active:focus,
button.bg-active:hover,
button.bg-active:focus {
  background-color: #b2f6ffb0 !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
  width: 6px;
}
::-webkit-scrollbar:horizontal {
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 1px solid #fff;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}

.uploaded-media {
  width: 120px;
  height: 120px;
  bottom: 70px;
  left: 100px;
  position: absolute;
}

.uploaded-media img {
  width: 100%;
  height: 100%;
}

.inbox-uploaded-media {
  max-height: 160px;
}

.inbox-media-remove-button {
  position: absolute;
  top: 0;
  right: 0;
}

.font {
  font-size: 1.8rem;
}
.font-01 {
  font-size: 1rem !important;
}

.weight {
  font-weight: 700 !important;
}

.my-custom-scrollbar {
  position: relative;
  height: 300px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
