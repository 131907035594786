/**=====================
     51. Search CSS Start
==========================**/
.search-page{
  .search-form{
    input{
      background-position:99%;
      &:focus{
        outline: none;
      }
    }
  }
  .nav-link{
    font-weight: 500;
    text-transform: uppercase;
  }
  .nav-tabs{
    padding-bottom: 10px;
    .nav-item {
      border-bottom: none;
    }
    .nav-link{
      color: $semi-dark;
      &.active{
        background-color: rgba($primary-color, 0.2);
        border: 1px solid rgba($primary-color, 0.2);
        color: $primary-color;
        margin-right: 10px;
        border-radius: 5px;
      }
    }
    .dropdown-menu {
      box-shadow: none;
    }
  }


  .info-block{
    + .info-block{
      padding-top: 20px;
      border-top: 1px solid $light-color;
      margin-top: 20px;
    }
  }
  .border-tab.nav-tabs{
    align-items: left;
    justify-content: end;
    .nav-item{
      width: auto;
    }
  }
  .search-links{
    h6{
      margin-bottom: 3px;
    }
  }
  p{
    text-transform: lowercase;
    margin-bottom: 0;
    color: $dark-color;
  }
  ul.search-info{
    li{
      display: inline-block;
      font-size: 12px;
      line-height: 1;
      color: #586082;
      + li{
        border-left: 1px solid lighten($dark-color , 50%);
        padding-left: 8px;
        margin-left: 8px;
      }
      i{
        color: $warning-color;
      }
    }
  }
  #video-links{
    .embed-responsive
    + .embed-responsive{
      margin-top: 30px;
    }
  }
}
/**=====================
    51. Search CSS Ends
==========================**/