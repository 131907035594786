/**=====================
     16. Popover CSS Start
==========================**/
.popover-header {
  background-color: $dark-color;
  color: $white;
}
.popover-offset{
  top: -20px !important;
}
/**=====================
     16. Popover CSS Ends
==========================**/