/**=====================
     54. Tour  CSS Start
==========================**/
.kVEayH {
  background: $primary-color !important;
  height:  unset !important;
}
.fKLrJo {
  color: $primary-color !important;
  background: $primary-color !important;
}
.eTpeTG {
  right : 18px !important;
}
.fyrYpc {
  &:focus {
    outline: none;
  }
}

/**=====================
    54. Tour  CSS Ends
==========================**/